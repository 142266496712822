import Head from "@/components/fragments/Head";
import Header from "@/components/fragments/Header";
import MainLayout from "@/components/fragments/main-layout/MainLayout";

export default function Index() {
  return (
    <>
      <Head>
        <title>トップページ - MADRAS</title>
      </Head>
      <Header ddAllowPrivacy />
      <MainLayout activeMenu="" title="トップページ" ddAllowPrivacy>
        この画面はトップページです。
        <br />
        メニュー表示をonにし、各管理画面に移動してください
      </MainLayout>
    </>
  );
}
