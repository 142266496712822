import NextHead from "next/head";
import { Children, memo, useMemo, isValidElement } from "react";
import { isDevelopment, isQa, isStg } from "@/helper/env";

const Head: React.FC<React.PropsWithChildren<unknown>> = memo(function Head({
  children,
}) {
  const env = useMemo(() => {
    if (isDevelopment()) return "<DEV> ";
    if (isStg()) return "<STG> ";
    if (isQa()) return "<QA> ";
    return "";
  }, []);

  const ch = Children.map(children, (child) => {
    if (isValidElement(child) && child.type === "title") {
      const copy = { ...child, props: { ...child.props } };
      copy.props.children = Array.isArray(child.props.children)
        ? [...child.props.children]
        : [child.props.children];
      copy.props.children.unshift(env);
      return copy;
    }
    return child;
  });

  return <NextHead>{ch}</NextHead>;
});

export default Head;
